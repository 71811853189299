<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Carga de Aviso LapiKids</p>
      <button
          type="button"
          class="delete"
          @click="$parent.close()"/>
    </header>
    <section class="modal-card-body">
      <div class="content">
        <div class="columns is-multiline">
          <div class="column is-12">
            <ul class="no-list">
              <li><strong>Orden: </strong>{{ order.order_new }}</li>
              <li><strong>Paciente: </strong>{{ order.patient.full_name }}</li>
            </ul>
            <form @submit.prevent="onSubmit" id="maquila-form" enctype="multipart/form-data">
              <b-field class="file">
                <b-upload v-model="file" expanded accept=".pdf" required name="file">
                  <a class="button is-primary is-fullwidth">
                    <b-icon icon="upload"></b-icon>
                    <span>{{ file.name || "Clic para seleccionar archivo" }}</span>
                  </a>
                </b-upload>
              </b-field>
            </form>
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button native-type="button" type="is-primary" @click="$parent.close()">Cerrar</b-button>
      <b-button native-type="submit" form="maquila-form" type="is-default">Guardar</b-button>
    </footer>
  </div>
</template>

<script>
export default {
  name: "LapikidsAvisoModal",
  props: {
    order: Object
  },
  data() {
    return {
      file: {},
    }
  },
  methods: {
    onSubmit(e) {
      const formData = new FormData(e.target);
      this.$loader.show();
      this.$http.post(`/order/${this.order.order_new}/lapikids-aviso`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(() => {
        this.$parent.close();
        this.$buefy.dialog.alert({
          title: 'Procesado con éxito',
          type: 'is-success',
          hasIcon: true,
          icon: '',
          message: 'Documento almacenado con éxito'
        });
        this.$emit('reload');
      }).finally(() => {
        this.$loader.hide();
      });
    }
  }
}
</script>

<style scoped>
.no-list {
  list-style: none;
}
</style>