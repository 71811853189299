<template>
  <div>
    <div class="section-title section-title_app">
      <h3>Captura de orden</h3>
    </div>
    <div class="column is-12 has-background-white">
      <div style="display: flex; flex-wrap: wrap; gap: 16px; padding: 10px; align-items: center;">
        <b-tooltip
            v-if="$alfalab.canAskPatientSign(this)"
            label="Documentos">
          <b-button icon-left="file" type="is-primary" @click="$store.dispatch('setShowDocumentsModal', true)"></b-button>
        </b-tooltip>
        <b-tooltip
            v-if="!!data.invoice && !data.invoice.is_general_public"
            label="Información de Factura">
          <b-button
              icon-left="wallet"
              @click="showInvoiceModal = true"
              type="is-primary"> </b-button>
        </b-tooltip>
        <b-tooltip
            v-if="data && data.patient && data.patient.lapifan_active && data.patient.lapifan_active.status"
            label="Lapifan">
          <b-button
              @click="handleLapifanClick"
              style="color: gold"
              icon-left="crown"></b-button>
        </b-tooltip>
        <b-tooltip
            v-if="readOnly && false"
            label="Carga de PDF Maquila">
          <b-button
              v-if="$alfalab.enabledUploadMaquila($auth.user())"
              type="is-primary"
              @click="showPDFMaquila = true"
              icon-left="laptop-medical"></b-button>
        </b-tooltip>
        <b-tooltip
            v-if="readOnly && $alfalab.inBranch(this, ['40'])"
            label="Aviso de privacidad">
          <b-button
              type="is-primary"
              @click="showPdfAvisoLapiKids = true"
              icon-left="laptop-medical"></b-button>
        </b-tooltip>
        <div class="notification is-warning">
          No olvides actualizar el número de teléfono móvil para el WhatsApp en el campo teléfono móvil y el correo electrónico en el campo correo.
        </div>
      </div>
    </div>
    <div class="column">
      <b-field label="Fecha"
               label-position="on-border">
        <b-datepicker v-model="data.date"
                      editable
                      position="is-bottom-right"
                      disabled
                      :unselectable-days-of-week="[0, 6]">
        </b-datepicker>
      </b-field>
    </div>
    <div class="column is-3" v-if="$alfalab.enableForeignWorkUI($auth.user())">
      <search-order label="Número de PreOrden"
                    v-model="data.preorder"
                    v-if="$alfalab.typeSearchAuxForeignWork($auth.user()) === 'quotation'"
                    type="preorder"
                    :withLapiLabels="true"
                    :disabled="readOnly">
      </search-order>
      <search-order label="Número Empleado"
                    v-model="data.employer"
                    v-if="$alfalab.typeSearchAuxForeignWork($auth.user()) === 'ard57-nov2023'"
                    type="ard57-nov2023"
                    :withLapiLabels="true"
                    :disabled="readOnly">
      </search-order>

      <search-order label="Número Empleado"
                    v-model="data.employer"
                    v-if="$alfalab.typeSearchAuxForeignWork($auth.user()) === 'minas-2024'"
                    type="minas-2024"
                    :withLapiLabels="true"
                    :disabled="readOnly">
      </search-order>
    </div>
    <div class="column is-3">
      <search-order label="Número general"
                    v-model="data.order_new"
                    type="order"
                    :withLapiLabels="true"
                    :disabled="readOnly">
      </search-order>
    </div>
    <div class="column is-4">
      <select-branch-office label="Sucursal"
                            label-position="on-border"
                            :disabled="true"
                            v-model="data.branch_office">

      </select-branch-office>
    </div>
    <div class="column is-3">
      <select-sub-branch-office
          label="SubSucursal"
          label-position="on-border"
          v-model="data.sub_branch_office"
          :branch-office="data.branch_office"
          :readonly="readOnly"
          v-if="!$auth.user().sub_branch_office"
      ></select-sub-branch-office>
      <b-field label="SubSucursal" v-else>
        {{$auth.user().sub_branch_office.name}}
      </b-field>
    </div>
    <div class="column is-2">
      <search-order label="Número Local"
                    v-model="data.no_local"
                    type="local"
                    :withLapiLabels="true"
                    :disabled="readOnly">
      </search-order>
    </div>
    <div class="column is-3">
      <search-order label="Pase médico"
                    v-model="data.medical_pass"
                    type="medical_pass"
                    :disabled="readOnly">
      </search-order>
    </div>
    <div class="column is-2">
      <search-order label="Folio"
                    v-model="data.folio"
                    type="folio"
                    :withLapiLabels="true"
                    :disabled="readOnly">
      </search-order>
      <b-field v-if="false" label="Folio" label-position="on-border">
        <b-input :disabled="readOnly" v-model="data.folio"></b-input>
      </b-field>
    </div>
    <div class="column is-2">
      <b-field label="Pase/Referencia" label-position="on-border">
        <b-input :disabled="readOnly" v-model="data.pass"></b-input>
      </b-field>
    </div>
    <div class="column is-3">
      <select-date label="Fecha Receta"
                   :disabled="readOnly"
                   v-model="data.date_prescription"
                   disable-initial-date
      ></select-date>
    </div>

    <div class="column is-2">
      <b-field label="Hora T. Muestra">
        <b-input type="time" :disabled="readOnly" v-model="data.extra_6"/>
      </b-field>
    </div>

    <div class="column is-3">
      <b-field label="Ubicación">
        <b-select :disabled="readOnly" v-model="data.location">
          <option value=""></option>
          <option :value="location.id" v-for="location in locations" :key="location.id">{{location.name}}</option>
        </b-select>
      </b-field>
    </div>

    <div class="column is-2">
      <b-field label="Número">
        <b-input :disabled="readOnly" v-model="data.location_number" type="number"/>
      </b-field>
    </div>

    <template v-if="$alfalab.showTicketInput(this)">
    <div class="column is-4" v-if="readOnly"></div>
    <div class="column is-3">
      <b-field label="Ticket">
        <b-input v-model="data.dispenser_ticket" type="text"/>
      </b-field>
    </div>
    <div class="column is-2" v-if="readOnly">
      <b-field>
        <b-button
            @click="$emit('update-dispenser-ticket')"
            type="is-primary">Actualizar Ticket</b-button>
      </b-field>
    </div>
    </template>

    <div class="column is-6"></div>

    <div class="column is-7">
      <select-company
          v-if="!readOnly"
          :saved="saved"
          :disabled="!$alfalab.canEditCompany(this)"
          v-model="data.company"
          label-position="on-border"
          :clearable="$alfalab.canEditCompany(this)"
      />
      <b-field v-else label="Empresa">
        <b-input  :value="data.company ? data.company.name : ''" :disabled="true"></b-input>
      </b-field>

    </div>
    <div class="column is-1">
      <b-checkbox size="is-small" v-if="hasCompany"
                  v-model="saved.company"
      >
        <b-icon icon="lock"/>
      </b-checkbox>
    </div>
    <div class="column is-3" v-if="hasCompany">
      <company-form-conditions :company="company"
                               :read-only="!$alfalab.canEditCompany(this)"
      >
      </company-form-conditions>
    </div>
    <div class="column is-12" v-if="hasCompany && !readOnly">
      <b-message type="is-danger" v-if="messageForReception !== ''">
        <MarqueeText :message="messageForReception"/>
      </b-message>
    </div>
    <div class="column is-12" v-show="! hasPatient">
      <div class="is-divider" data-content="Paciente"></div>
      <select-patient class="columns"
                      :patient.sync="data.patient"
                      :has-company="hasCompany"
                      @selectOrder="val => $emit('setData', val)"
      ></select-patient>
    </div>
    <div class="column is-12">
      <div class="is-divider" data-content="Médico"></div>
      <div class="columns">
        <select-medical v-if="false"
            class="column is-7"
            label-position="on-border"
            :show-update-medical="$alfalab.showEditMedicalButton(this)"
            @update-medical="$emit('update-medical')"
            :disabled="noMedical||(readOnly && !$alfalab.showEditMedicalButton(this))"
            :medical="medical">
        </select-medical>
        <select-medical
            class="column is-7"
            label-position="on-border"
            :disabled="noMedical||readOnly"
            :medical="medical">
        </select-medical>
        <div class="column" v-if="!$alfalab.branchDontSetMedical(this)">
          <b-field label="A quien corresponda" label-position="on-border">
            <b-field>
              <br>
              <b-checkbox :value="noMedical"
                          v-if="false"
                          :disabled="readOnly && !$alfalab.showEditMedicalButton(this)"
                          size="is-small"
                          @input="onCheckbox">
              </b-checkbox>
              <b-checkbox :value="noMedical"
                          :disabled="readOnly"
                          size="is-small"
                          @input="onCheckbox">
              </b-checkbox>
            </b-field>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Clave con empresa"
                   label-position="on-border"
                   style="max-width: 150px">
            <b-input v-if="false" :disabled="readOnly" v-model="medicalCode"></b-input>
            <b-input  :disabled="readOnly  && !$alfalab.showEditMedicalButton(this)" v-model="medicalCode"></b-input>
          </b-field>
        </div>
      </div>
    </div>
    <b-modal v-model="showDocumentsModal">
      <order-documents
          :order="data.order_new"
          :data="data"
          @order-update-patient-sign="$emit('order-update-patient-sign')"/>
    </b-modal>

    <b-modal v-model="showInvoiceModal" v-if="!!data.id && !!data.invoice">
      <order-invoice :invoice="data.invoice" :order-id="data.id"/>
    </b-modal>

    <b-modal v-model="showInBody">
      <data-in-body/>
    </b-modal>

    <b-modal v-model="showLapifan"  v-if="data && data.patient && data.patient.lapifan_active && data.patient.lapifan_active.status">
      <lapifan-modal :patient="data.patient"/>
    </b-modal>

    <b-modal v-model="showPDFMaquila" v-if="false">
      <maquila-modal :order="data" />
    </b-modal>
    <b-modal v-model="showPdfAvisoLapiKids" v-if="$alfalab.inBranch(this, ['40'])">
      <lapikids-aviso-modal :order="data" />
    </b-modal>
  </div>
</template>

<script>
import SelectCompany from "../company/SelectCompany";
import SelectPatient from "../patient/SelectPatient";
import SelectBranchOffice from "../brach-office/SelectBranchOffice";
import SearchOrder from "./SearchOrder";
import SelectSubBranchOffice from "../sub-branch-office/SelectSubBranchOffice";
import SelectMedical from "../medical/SelectMedical";
import ModalFolioImss from "../modals/ModalFolioImss";
import {mapGetters,mapState} from 'vuex';
import CompanyFormConditions from "@/components/company/CompanyFormConditions";
import SelectDate from "@/components/partials/SelectDate";
import MarqueeText from "@/components/MarqueeText";
import OrderDocuments from "@/components/order/OrderDocuments";
import OrderInvoice from "@/components/order/OrderInvoice";
import DataInBody from "@/components/in-body/DataInBody";
import LapifanModal from "../lapifan/LapifanModal.vue";
import MaquilaModal from "../maquila/MaquilaModal.vue";
import LapikidsAvisoModal from "../lapi-kids/LapikidsAvisoModal.vue";

export default {
  name: "FormOrder",
  components: {
    SelectDate,
    CompanyFormConditions,
    ModalFolioImss, SelectMedical,
    SelectSubBranchOffice, SearchOrder, SelectBranchOffice, SelectPatient, SelectCompany,
    MarqueeText,
    OrderDocuments,
    OrderInvoice,
    DataInBody,
    LapifanModal,
    MaquilaModal, LapikidsAvisoModal
  },
  props: {
    data: Object,
    errors: Array|Object,
    readOnly: Boolean,
    noMedical: Boolean,
    saved: {
      type: Object,
      default: {
        studies: false,
        company: false
      }
    },
  },
  data() {
    return {
      apiUrl: 'patients',
      params: {
        perPage: 20,
        page: 1
      },
      activeModalFolio: false,
      showInvoiceModal: false,
      showInBody: false,
      showLapifan: false,
      showPDFMaquila: false,
      showPdfAvisoLapiKids: false
    }
  },
  computed: {
    orderNew() {
      return this.data.order_new ? this.data.order_new.substr(1).replace(/\s/g, '') : null
    },
    ...mapState('order', [
      'company', 'medical'
    ]),
    ...mapGetters('order', [
      'hasCompany', 'hasPatient'
    ]),
    messageForReception() {
      if (this.hasCompany) {
        return this.company.message_for_reception.replace(/^\s+|\s+$/g, '');
      }
      return '';
    },
    locations(){
      return this.$store.state.main.locations || [];
    },
    medicalCode: {
      set(val){
        this.$store.dispatch('order/setMedicalCode', val);
      },
      get(){
        return this.$store.state.order.medicalCode;
      }
    },
    showDocumentsModal: {
      get() {
        return this.$store.state.utils.showDocumentsModal;
      },
      set(val) {
        this.$store.dispatch('setShowDocumentsModal', val);
      }
    },
  },
  methods: {
    onCheckbox(val) {
      this.$emit('update:noMedical', val);
      if (val) {
        this.$store.dispatch('order/setDefaultMedical');
      } else {
        this.$store.dispatch('order/setMedical', {});
      }
    },
    handleLapifanClick() {
      this.showLapifan = true;
    }
  }
}
</script>

<style scoped>

</style>
