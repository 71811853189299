<template>
  <div>
    <b-field>
      <b-switch v-model="data.customerServiceAllProducts">¿Encontró todo lo que buscaba?</b-switch>
    </b-field>
    <b-field v-if="!data.customerServiceAllProducts" label="Descripción de productos no encontrados">
      <b-input type="textarea"
               v-model="data.customerServiceDescription"
      />
    </b-field>
    <b-field v-if="canUpdate">
      <button class="button is-primary is-rounded"
              type="button"
              @click="$emit('update')">
        Actualizar información
      </button>
    </b-field>
  </div>
</template>

<script >
export default  {
  name: "CustomerService",
  props: {
    data: Object,
    canUpdate: Boolean
  }
}
</script>

<style scoped>

</style>