<template>
  <b-tabs size="is-small" @input="onInputTabs">
    <b-tab-item label="Toma" value="foreign_work">
      <ForeignWork :data="data" :can-update="canUpdate"/>
    </b-tab-item>
    <b-tab-item label="Instrucciones" value="updateInstructionDelivery">
      <b-field label="Instrucciones de entrega"
               label-position="inside">
        <b-input type="textarea"
                 ref="updateInstructionDelivery"
                 v-model="data.instructions_delivery"
        >
        </b-input>
      </b-field>
      <b-field v-if="canUpdate">
        <button class="button is-primary is-rounded"
                type="button"
                @click="updateInstruction('updateInstructionDelivery')">
          Actualizar Instrucciones
        </button>
      </b-field>
    </b-tab-item>
    <b-tab-item label="Observaciones" value="updateObservation">
      <b-field label="Observaciones"
               label-position="inside">
        <b-input type="textarea"
                 ref="updateObservation"
                 v-model="data.observations">
        </b-input>
      </b-field>
      <b-field v-if="canUpdate">
        <button class="button is-primary is-rounded"
                type="button"
                @click="updateInstruction('updateObservation')">
          Actualizar Observaciones
        </button>
      </b-field>

    </b-tab-item>
    <b-tab-item label="Pendientes" value="updatePendingObservation">
      <b-field label="Observaciones Pendientes de entrega"
               label-position="inside">
        <b-input type="textarea"
                 ref="updatePendingObservation"
                 v-model="data.pending_delivery">
        </b-input>
      </b-field>
      <b-field v-if="canUpdate">
        <button class="button is-primary is-rounded"
                type="button"
                @click="updateInstruction('updatePendingObservation')">
          Actualizar Pendientes
        </button>
      </b-field>
    </b-tab-item>
    <b-tab-item label="Campos extra" value="extra_values">
      <b-field :label="labelExtra1">
        <b-input v-model="data.extra_1" ref="extra_values"></b-input>
      </b-field>
      <b-field :label="labelExtra2">
        <b-input v-model="data.extra_2"></b-input>
      </b-field>
      <b-field :label="labelExtra3">
        <b-input v-model="data.extra_3"></b-input>
      </b-field>
      <b-field :label="labelExtra4">
        <b-input v-model="data.extra_4"></b-input>
      </b-field>
      <b-field :label="labelExtra5">
        <b-input v-model="data.extra_5"></b-input>
      </b-field>
      <b-field :label="labelExtra6">
        <b-input v-model="data.extra_6"></b-input>
      </b-field>
      <b-field v-if="canUpdate">
        <button class="button is-primary is-rounded"
                type="button"
                @click="updateExtraFields('updateExtraFields')">
          Actualizar Campos Extra
        </button>
      </b-field>
    </b-tab-item>
    <b-tab-item label="Diagnósticos" value="diagnosticos">
      <OrderDiagnostics :can-update="canUpdate" :data="data"/>
    </b-tab-item>
    <b-tab-item label="Resultados físicos">
      <OrderPhysicalResults :data="data"
                            :canUpdate="canUpdate"
                            @updatePhysicalResults="updatePhysicalResults"/>
    </b-tab-item>
    <b-tab-item label="Atención al cliente">
      <CustomerService :data="data" :canUpdate="canUpdate" @update="updateCustomerService"/>
    </b-tab-item>
    <b-tab-item label="Muestras" v-if="data.samples && data.samples.length > 0">
      <OrderSamples :data="data"/>
    </b-tab-item>
    <b-tab-item label="Historico" v-if="$alfalab.allowShowHistorico($auth.user(), data)">
      <b-tabs>
        <b-tab-item label="Nueva">
          <OrderHistory :order="data"/>
        </b-tab-item>
        <b-tab-item label="Web">
          <OrderHistoryWeb :order="data"/>
        </b-tab-item>
      </b-tabs>
    </b-tab-item>
  </b-tabs>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import OrderDiagnostics from "@/components/order/OrderDiagnostics";
import OrderSamples from "@/components/order/OrderSamples";
import OrderHistory from "@/components/order/OrderHistory";
import OrderHistoryWeb from "@/components/order/OrderHistoryWeb";
import ForeignWork from "@/components/order/ForeignWork";
import OrderPhysicalResults from "@/components/order/OrderPhysicalResults";
import CustomerService from "@/components/order/CustomerService";


export default {
  name: "OrderBoxObservations",
  components: {
    OrderDiagnostics, OrderSamples, OrderHistory,
    OrderHistoryWeb, ForeignWork, OrderPhysicalResults,
    CustomerService
  },
  props: {
    data: Object,
    canUpdate: Boolean
  },
  data() {
    return {
      apiUrl: '/order-update-observation',
      params: {
        action: '',
        observation: ''
      },
      extraFields: this.$store.state.main.extraFields || []
    }
  },
  computed: {
    ...mapState('order', [
      'company'
    ]),
    ...mapGetters('order', [
      'hasDataVaccineCovid'
    ]),
    labelExtra1() {
      const extra = this.extraFields.find(item => {
        return item.key.toString() === '1'
      })
      if (this.hasDataVaccineCovid) {
        return 'No. Lote';
      }
      if (extra) {
        return extra.value;
      }
      return this.company.extra_1 || 'Extra 1';
    },
    labelExtra2() {
      const extra = this.extraFields.find(item => {
        return item.key.toString() === '2'
      })
      if (this.hasDataVaccineCovid) {
        return 'Lugar de nacimiento';
      }
      if (extra) {
        return extra.value;
      }
      return this.company.extra_2 || 'Extra 2';
    },
    labelExtra3() {
      const extra = this.extraFields.find(item => {
        return item.key.toString() === '3'
      })
      if (this.hasDataVaccineCovid) {
        return 'Grupo y RH';
      }
      if (extra) {
        return extra.value;
      }
      return this.company.extra_3 || 'Extra 3';
    },
    labelExtra4() {
      const extra = this.extraFields.find(item => {
        return item.key.toString() === '4'
      })
      if (this.hasDataVaccineCovid) {
        return 'Estado civil';
      }
      if (extra) {
        return extra.value;
      }
      return this.company.extra_4 || 'Extra 4';
    },
    labelExtra5() {
      const extra = this.extraFields.find(item => {
        return item.key.toString() === '5'
      })
      if (extra) {
        return extra.value;
      }
      return this.company.extra_5 || 'Extra 5';
    },
    labelExtra6() {
      const extra = this.extraFields.find(item => {
        return item.key.toString() === '6'
      })
      if (extra) {
        return extra.value;
      }
      return this.company.extra_6 || 'Extra 6';
    },
  },
  methods: {
    updateInstruction(action) {
      this.params.observation = this.$refs[action].value;
      this.params.action = action;
      this.updateAction();
    },
    updateAction() {
      this.$loader.show();
      this.updateData(this.data.id, this.params).then(data => {
        this.$loader.hide();
        this.$toast.success(data.message);
      }).catch(error => {
        this.$alfalab.showError(error);
      }).finally(() => {
        this.$loader.hide();
      });
    },
    updateExtraFields(action) {
      const {extra_1, extra_2, extra_3, extra_4, extra_5, extra_6} = this.data;
      this.params.extra = {
        extra_1, extra_2, extra_3, extra_4, extra_5, extra_6
      };
      this.params.action = action;
      this.updateAction();
    },
    onInputTabs(val) {
      if (this.$refs[val]) {
        this.$refs[val].focus();
      }
    },
    updateDiagnostics(diagnostics) {
      this.params.diagnostics = diagnostics;
      this.params.action = 'updateDiagnostics';
      this.updateAction();
    },
    updatePhysicalResults() {
      const params = {
        action: 'updatePhysicalResults',
        physicalResults: this.data.requirePhysicalResults ? this.data.physicalResults : '',
        requirePhysicalResults: this.data.requirePhysicalResults
      };


      this.updateData(this.data.id, params).then(data => {
        this.$loader.hide();
        this.$toast.success(data.message);
      }).catch(error => {
        this.$alfalab.showError(error);
      }).finally(() => {
        this.$loader.hide();
      });
    },
    updateCustomerService(){
      const params = {
        action: 'updateCustomerService',
        customer_service_all_products: this.data.customerServiceAllProducts,
        customer_service_description: !this.data.customerServiceAllProducts ? this.data.customerServiceDescription : '',
      };

      this.updateData(this.data.id, params).then(data => {
        this.$loader.hide();
        this.$toast.success(data.message);
      }).catch(error => {
        this.$alfalab.showError(error);
      }).finally(() => {
        this.$loader.hide();
      });
    }
  }
}
</script>

<style scoped>

</style>
