import { render, staticRenderFns } from "./DataOrderPatient.vue?vue&type=template&id=05e3d393&scoped=true"
import script from "./DataOrderPatient.vue?vue&type=script&lang=js"
export * from "./DataOrderPatient.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05e3d393",
  null
  
)

export default component.exports